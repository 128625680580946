import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import VoiceFilterHandlerModule from "./VoiceFilterHandlerModule";

export default class VoiceFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: VoiceFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "voice_chat",
    color: "rgba(var(--a-primary), 1)"
  };
  view =  () => import('@/components/Modules/modules/Filters/VoiceFilterHandlerModuleView.vue')
}

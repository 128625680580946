import GreetingProtection
  from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection/ReputationCalculationMode/GreetingProtection";
import { GreetingStringStrictModeEnum } from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/types";

export default abstract class BaseGreetingProtection extends GreetingProtection{
  remove_not_confirm_users_timeout = 300
  ban_unverified_users_time = 60
  remove_hellow_after_button_pressed = false
  remove_hellow_button = false
  mode: GreetingStringStrictModeEnum = GreetingStringStrictModeEnum.Restrict
}

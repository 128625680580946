const state = {
	user: {
		// name        : "Name",
		// about       : "About",
		// img         : "",
		// languageCode: "",
	},
};

export default state;

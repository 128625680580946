import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import BotReplyFilterHandlerModule from "./BotReplyFilterHandlerModule";

export default class BotReplyFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: BotReplyFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "android",
    color: "rgba(var(--a-primary), 1)"
  };
  view =  () => import('@/components/Modules/modules/Filters/BotReplyFilterHandlerModuleView.vue')
}

import { ModuleConditionTypeEnum, ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import SendWakeupMessageModule
  from "@/includes/logic/Modules/models/modules/SendWakeupMessageModule/SendWakeupMessageModule";

export default class SendWakeupMessageModuleBuilder extends ModuleBuilder {
  model!: SendWakeupMessageModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/Activity/SendWakeupMessageModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'auto_graph',
  }

  conditionsTypes: Array<ModuleConditionTypeEnum> = [
      ModuleConditionTypeEnum.WorkTime
  ]
}


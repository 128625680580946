import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import StickerFilterHandlerModule from "./StickerFilterHandlerModule";

export default class StickerFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: StickerFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "insert_emoticon",
    color: "rgba(var(--a-warning), 1)"
  };
  view =  () => import('@/components/Modules/modules/Filters/StickerFilterHandlerModuleView.vue')
}

import i18n from "@/i18n/i18n";
import Api from "../../includes/logic/Api";
import router from "@/router/router";
import moderation from "./chat/moderation/actions";
import chatActivityActions from "./chatActivitySettings/actions";
import chatRuntime from "./chatRuntime/actions";
import questionnaire from "./questionnaire/actions";
import { successNotification, errorNotification } from "@/includes/NotificationService";
import { ChatModulesManager, setModules } from "@/includes/logic/Modules/ModulesManager";
import ChatApi from "@/includes/Api/Chat.api";
import { RootState } from "../state";
import { ChatConfigSettings, SetChatCommonConfigReq } from "@/includes/types/Chat/types";
import { isDeprecatedChatConfig } from "@/includes/types/Chat/predicates";

import { Commit, Dispatch } from "vuex";
import { AxiosError, isAxiosError } from "axios";
import Vue from "vue";

export default {
  rollbackChatConfig({ commit }: { commit: Commit }) {
    commit('ROLLBACK_CHAT_CONFIG')
  },
  requestChatsInfo({ state, commit }: { state: RootState, commit: Commit }) {
    if (state.chatState.loadingChatsInfoPromise === null) {
      const loadingChatsInfoPromise =  new Promise((resolve, reject) => {
        ChatApi.getChatsInfo()
            .then(res => {
              commit("updateChatsInfo", res.data);
              resolve(res);
            })
            .catch(error => {
              commit("updateChatsInfo", { chats: [] });

              errorNotification(error);
              reject(error);
            })
            .finally(() => {
              commit('setObjectField', {
                object    : state.chatState,
                objectPath: "loadingChatsInfoPromise",
                value     : null,
              })
            })
      });

      commit('setObjectField', {
        object    : state.chatState,
        objectPath: "loadingChatsInfoPromise",
        value     : loadingChatsInfoPromise,
      })

      return loadingChatsInfoPromise
    }

    return state.chatState.loadingChatsInfoPromise
  },
  migrateChatToModules({ dispatch, getters }: { dispatch: Dispatch, getters: any }, vm: Vue) {
    vm.$baseTemplate.loader.open()

    return ChatApi.migrateChatToModules(getters.chatId)
        .then(({ data }) => {
          dispatch('updateChatState', data)

          setModules(data.chat.modules_config, ChatModulesManager)

          return data
        })
        .catch(errorNotification)
        .finally(() => {
          vm.$baseTemplate.loader.close()
        })

  },
  updateChatState({ commit }: { commit: Commit }, data: { chat: ChatConfigSettings }) {
    commit("updateChat", data.chat);
  },
  requestChatConfig({ dispatch }: { dispatch: Dispatch }, { id }: { id: number | string }) {
    return Api.getChatConfig("tg", { chat_id: id })
        .then(res => {
          dispatch('updateChatState', res.data)

          setModules(res.data.chat.modules_config, ChatModulesManager)
        })
        .catch(error => {
          if (isAxiosError(error)) {
            const httpError = error as AxiosError
            const errStatus = httpError.response?.status;

            if (errStatus) {
              if (errStatus === 405) {
                router.push({ name: "login" });
              }

              if (errStatus === 404 || errStatus === 400) {
                router.push({ name: "chats" });
              }
            }

            errorNotification(error);
          }
        });
  },
  saveChatConfigRequest({ state, commit, dispatch }: { state: RootState, commit: Commit, dispatch: Dispatch }, payload?: SetChatCommonConfigReq){
    return new Promise<void>((resolve, reject) => {
      const { chat } = state.chatState

      if (chat) {
        //TODO remove chat type check later
        if (isDeprecatedChatConfig(chat)) {
          Vue.prototype.$baseTemplate.loader.open();

          Api.setChatConfig("tg", {
            chat_id: chat.chat_id,
            chat: {
              config: chat.config,
              language_code: chat.language_code,
              triggers: chat.triggers,
              timezone: chat.timezone,
            },
          })
              .then((res) => {
                // update chat state
                dispatch('updateChatState', res.data)

                // update modules
                setModules(res.data.chat.modules_config, ChatModulesManager)

                commit("updatedTriggerId", null);

                successNotification(i18n.t("success").toString(), i18n.t("configuration_uploaded").toString());
                resolve();
              })
              .catch(error => {
                console.error(error);
                errorNotification(error);
                reject();
              })
              .finally(() => {
                Vue.prototype.$baseTemplate.loader.close();
              });
        } else {
          if (payload) {
            Vue.prototype.$baseTemplate.loader.open();

            ChatApi.setChatCommonConfig(chat.chat_id, { ...payload, config_version: chat.config_version })
                .then((res) => {
                  // update chat state
                  dispatch('updateChatState', res.data)

                  // update modules
                  setModules(res.data.chat.modules_config, ChatModulesManager)

                  commit("updatedTriggerId", null);

                  successNotification(i18n.t("configuration_uploaded").toString());
                  resolve();
                })
                .catch(error => {
                  console.error(error);
                  errorNotification(error);
                  reject();
                })
                .finally(() => {
                  Vue.prototype.$baseTemplate.loader.close();
                });
          }
        }
      }
    })
  },
  saveChatConfig({ dispatch }: { dispatch: Dispatch }, payload?: SetChatCommonConfigReq) {
    // Сохранение настроек
    return new Promise<void>((resolve, reject) => {
      Vue.prototype.$confirm({
        title: i18n.t("confirm_save_chat_config_title").toString(),
        content: i18n.t("confirm_save_chat_config").toString(),
        okText: i18n.t("accept").toString(),
        cancelText: i18n.t("reject").toString(),
        onOk: function () {
          dispatch('saveChatConfigRequest', payload)
              .then(() => resolve())
              .catch(() => reject())
        },
        onCancel: () => dispatch('rollbackChatConfig')
      });
    });

  },
  // brand
  setChatBrand({ state, commit }: { state: RootState, commit: Commit }, { token }: { token: string }) {
    Vue.prototype.$baseTemplate.loader.open();

    Api.setChatBrand("tg", {
      chat_id: state.chatState.chat!.chat_id,
      token: token,
    })
        .then((res) => {
          // update chat state
          commit("updateChat", res.data.chat);

          successNotification();
        })
        .catch(error => {
          if (error.response !== undefined) {
            errorNotification(error);
          }
        })
        .finally(() => {
          Vue.prototype.$baseTemplate.loader.close();
        });
  },
  resetChatBrand({ state, commit }: { state: RootState, commit: Commit }) {
    Vue.prototype.$baseTemplate.loader.open();

    return new Promise<void>(function (resolve, reject) {
      Api.resetChatBrand("tg", { chat_id: state.chatState.chat!.chat_id })
          .then(res => {
            // update chat state
            commit("updateChat", res.data.chat);
            successNotification();

            resolve();
          })
          .catch(error => {
            if (error.response !== undefined) {
              errorNotification(error);
              reject(error);
            }
          })
          .finally(() => {
            Vue.prototype.$baseTemplate.loader.close();
          });
    });
  },
  getBrandConfig({ state, commit }: { state: RootState, commit: Commit }, { chat_id }: { chat_id: number }) {
    Vue.prototype.$baseTemplate.loader.open();

    return Api.getBrandConfig("tg", { chat_id })
        .then((res) => {
          // update chat state
          commit("SET_BRAND_BOT_CONFIG", res.data.config);
        })
        .catch(errorNotification)
        .finally(() => {
          Vue.prototype.$baseTemplate.loader.close();
        });
  },
  saveBrandConfig({ state, commit }: { state: RootState, commit: Commit }, { chat_id }: { chat_id: number }) {
    Vue.prototype.$baseTemplate.loader.open();

    Api.setBrandConfig("tg", {
      chat_id,
      config: state.chatState.brandConfig,
    })
        .then(() => {
      successNotification();
    })
        .catch(error => {
      if (error.response !== undefined) {
        errorNotification(error);
      }
    })
        .finally(() => {
      Vue.prototype.$baseTemplate.loader.close();
    });
  },
  // Chat activity actions
  ...chatActivityActions,
  // Chat runtime actions
  ...chatRuntime,
  ...moderation,
  ...questionnaire,
};

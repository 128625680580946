import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import BotCommandFilterHandlerModule from "./BotCommandFilterHandlerModule";

export default class BotCommandFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: BotCommandFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "developer_mode",
    color: "rgba(var(--a-dark), 1)"
  };
  view = () => import('@/components/Modules/modules/Filters/BotCommandFilterHandlerModuleView.vue')
}

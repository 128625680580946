import chatGetters             from "./chats/getters";
import userGetters             from "./user/getters";
import moderationFilterGetters from "./moderation-filters/getters";
import postsPlannerGetters     from "@/store/postsPlanner/getters";
import formsGetters             from './chats/questionnaire/getters'
import networksGetters             from './networks/getters'
import { RootState } from "@/store/state";

const getters = {
  getFilterTemplates : (state:RootState) => {
    return {
      "filter_templates_ru": state.AdminTemplate?.runtime_config && state.AdminTemplate.runtime_config.filter_templates_ru,
      "filter_templates_en": state.AdminTemplate?.runtime_config && state.AdminTemplate.runtime_config.filter_templates_en,
    };
  },
  isInfibotProject   : () => process.env.VUE_APP_TARGET_PROJECT === "infibot",
  isChatkeeperProject: () => process.env.VUE_APP_TARGET_PROJECT === "chatkeeperbot",
  ...userGetters,
  ...chatGetters,
  ...moderationFilterGetters,
  ...postsPlannerGetters,
  ...formsGetters,
  ...networksGetters
};

export default getters;

import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import ChatTopCommandHandlerModule from "./ChatTopCommandHandlerModule";

export default class ChatTopCommandHandlerModuleBuilder extends ModuleBuilder {
  model!: ChatTopCommandHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/ChatTopCommandHandlerModuleView.vue')

  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'star_half',
  }
}


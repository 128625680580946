import i18n from "@/i18n/i18n";
import { Board } from "@/views/posts-planner/posts.types";
import store from "@/store/store";
import { getNetworkTitle } from "@/includes/logic/Networks/utils";

import TemplateContext from 'piramis-base-components/src/plugins/AdminTemplate/includes/TemplateContext'
import { BreadcrumbStruct } from "piramis-base-components/src/plugins/AdminTemplate/includes/types/MenuItem";

import { Route } from "vue-router";

export function actionTypeBreadcrumb(ctx: TemplateContext): string {
  let label = ''

  if (ctx?.route.params.actionType === 'new') label = i18n.t('page_action_type_new').toString()
  if (ctx?.route.params.actionType === 'edit') label = i18n.t('page_action_type_edit').toString()
  if (ctx?.route.params.actionType === 'watch') label = i18n.t('page_action_type_watch').toString()

  return label
}

export const baseChatBreadcrumbs = () => [
  {
    iconName: 'icon-message-circle',
    routeName: 'chats',
  },
  {
    routeName: 'Community_dashboard',
    image: () => {
      if (store.getters.isChatSet) {
        return store.state.chatState.chat!.group_photo
      }

      return ''
    },
    label: () => {
      if (store.getters.isChatSet) {
        const { chat } = store.state.chatState

        return `${ chat!.group_title } (${ chat!.chat_id })`
      }

      return ''
    }
  },
]

export function baseBoardBreadcrumbs(ctx: Route) {
  const currentBoard: Board | null = store.state.postsPlanner.currentBoard

  if (currentBoard) {
    return [
      {
        iconName: 'icon-layout',
        routeName: 'boards',
      },
      {
        routeName: 'posts_planner',
        image: () => currentBoard.avatar,
        label: () => currentBoard.title + " (" + currentBoard.board.token_id + ")"
      },
    ]
  }

  return []
}

export function baseNetworkBreadcrumbs(ctx: Route, extra: Array<BreadcrumbStruct> = []) {
  const network = store.state.networksState.currentNetwork
  let breadcrumbs: Array<BreadcrumbStruct> = []

  if (network) {
    breadcrumbs = breadcrumbs
        .concat([
          {
            iconName: 'icon-database',
            routeName: 'NetworksList',
          },
          {
            routeName: 'NetworkDashboard',
            label: () => getNetworkTitle(network)
          },
        ])
        .concat(extra)
  }

  return breadcrumbs
}

import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import XpHandlerModule from "@/includes/logic/Modules/models/modules/XpHandlerModule/XpHandlerModule";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class XpHandlerModuleBuilder extends ModuleBuilder {
  model!: XpHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/XpHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ActivityBoost

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'diamond',
    iconStyle: 'outlined'
  }

  tariffTags(): Array<TagData> | null {
    return this.checkLimitOption('extended_reputation')
  }
}

import moment from "moment";
import { cloneDeep }      from "lodash";

export default class TriggerStatDataAdapter {
	_triggerId;
	_statisticsData;
	_fromDataTime;
	_toDataTime;
	_baseDataStructure = {
		time   : "",
		trigger: "",
		count  : 0,
	};
	_dateTimeFormat    = "YYYY-MM-DD HH:mm:ss";

	constructor(triggerId, statisticsData, from, to) {
		this._triggerId                 = triggerId;
		this._statisticsData            = statisticsData;
		this._fromDataTime              = from;
		this._toDataTime                = to;
		this._baseDataStructure.trigger = this._triggerId;
	}

	getData() {
		const tmpDataObject = {};
		const from          = moment(this._fromDataTime);
		const to            = moment(this._toDataTime);

		while(!from.isAfter(to)) {
			const currDate                 = from.format(this._dateTimeFormat);
			tmpDataObject[ currDate ]      = cloneDeep(this._baseDataStructure);
			tmpDataObject[ currDate ].time = currDate;
			from.add(1, "day");
		}
		this._statisticsData.forEach(dataItem => {
			if(tmpDataObject[ dataItem.time ]) {
				tmpDataObject[ dataItem.time ] = dataItem;
			}
		});

		return Object.values(tmpDataObject);
	}

	getDataFormattedForApexChartsStandard(triggerDataObject) {
		const rawData        = this.getData();
		let returnDataObject = {
			labels: [],
			series: [ {
				name: triggerDataObject.name,
				data: [],
			} ],
			total : 0,
		};
		rawData
			.forEach(dataItem => {
				returnDataObject.labels.push(dataItem.time);
				returnDataObject.series[ 0 ].data.push(dataItem.count);
				returnDataObject.total += dataItem.count;
			});
		return returnDataObject;
	}
}

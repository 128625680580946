import { cloneDeep, forIn, isUndefined, isEmpty }                     from "lodash";
import recursiveCompareObjects from "@/assets/utils/recursiveCompareObjects";

const actions = {
	filterConfigCompare    : ({ state, getters }, configType) => {
	  return new Promise(function(resolve, reject) {
			const chat      = state.chatState.chat;
			const config    = chat.config;
			const chatLang  = state.chatState.chat.language_code;
			const templates = getters.getFilterTemplates[ `filter_templates_${ chatLang }` ];

			if(!isEmpty(chat) && !isUndefined(config)) {
				const currTemplates = templates[ configType ]?.templates;

				if (currTemplates) {
				  forIn(currTemplates, function(value, key) {
				    const level          = parseInt(key.slice(-1));
				    const templateConfig = value.config;

				    if(recursiveCompareObjects(templateConfig, config)) {
				      resolve(level);
				    }
				  });
				}

				resolve(-1);
			} else {
				reject("Can`t use config");
			}
		});
	},
	/**
	 *
	 * @param state
	 * @param commit
	 * @param payload = {configType, level}
	 * @returns {Q.Promise<any>}
	 */
	setFilterTemplateConfig: ({ state, commit, getters }, payload) => {
		return new Promise(function(resolve, reject) {
			const templates      = getters.getFilterTemplates;
			const chatLang       = state.chatState.chat.language_code;
			const currTemplates  = templates[ `filter_templates_${ chatLang }` ][ payload.configType ]?.templates;

			if (currTemplates) {

			  const neededTemplate = cloneDeep(currTemplates[ `level${ parseInt(payload.level) }` ]?.config);

			  if(!isUndefined(neededTemplate)) {
			    commit("setFilterTemplateConfig", {
			      configType: payload.configType,
			      config    : neededTemplate,
			    });
			    resolve("Good");
			  } else {
			    reject("Level is undefined");
			  }
			}

			reject("Template is undefined");

		});
	},
};

export default actions;

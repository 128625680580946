import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import VotebanHandlerModule from "./VotebanHandlerModule";
import i18n from "@/i18n/i18n";

const DEFAULT_PROTECTION_MODEL = {
  count: 1,
  interval: 1800
}

export default class VotebanHandlerModuleBuilder extends ModuleBuilder {
  model!: VotebanHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/VotebanHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ModerationCommands

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'how_to_vote',
  }

  aliases: Array<string> = [ i18n.t('module_voteban_handler_module_alias_1').toString() ]

  private isProtectionActive = false

  get toggleProtection() {
    return this.isProtectionActive
  }

  set toggleProtection(value: boolean) {
    if (value) {
      if (this.model.params.protection === null) {
        this.model.params.protection = DEFAULT_PROTECTION_MODEL
      }
    } else {
      this.model.params.protection = null
    }

    this.isProtectionActive = value
  }
}

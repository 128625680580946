import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";

import { Type } from "class-transformer";

export default class SelfLinksFilterHandlerModule extends FilterModule {
  type: ModuleTypesEnum = ModuleTypesEnum.WebLinksFilterHandlerModule;

  @Type(() => SelfLinksFilterHandlerModuleParams)
  params!: SelfLinksFilterHandlerModuleParams
}

class SelfLinksFilterHandlerModuleParams {
  self_links_strict_mode!: boolean
  tg_links_whitelist!: BlackWhiteListItem<string>
  self_links_dont_use_dog!: boolean
  self_links_dont_use_tme!: boolean
  self_links_dont_use_join!: boolean
  self_links_dont_use_channels!: boolean
  self_links_dont_use_chats!: boolean
  self_links_dont_use_users!: boolean
  self_links_dont_use_bots!: boolean
  self_links_accept_mention_users!: boolean
  search_links_in_qr_codes!: boolean
}

import Api from "../../includes/logic/Api";

export default {
	actions: {
		SET_USER_QUESTIONNAIRE(ctx, { questionnaire }) {
			return Api.setQuestionnaire("tg", { questionnaire });
		},
	},
	getters: {
		USER_HAS_QUESTIONNAIRE: (state, getters, rootState) => rootState.userState.user.has_questionnaire,
	},
};

export default {
	setChatTriggers(state, arr) {
	  state.chatState.chat.triggers = arr.slice();
	},
  	enableTrigger(state, triggerId) {
  	  state.chatState.chat.triggers.push(triggerId);
  	},
  	disableTrigger(state, triggerId) {
	  const index = state.chatState.chat.triggers.indexOf(triggerId)

	  if (index !== -1) {
	    state.chatState.chat.triggers.splice(index, 1)
	  }
	}
};

import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types/types";
import { BoostsSourceEnum } from "@/includes/logic/Modules/models/conditions/BoostsCondition/types";

export default class BoostsCondition extends ConfigCondition {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.Boosts;
  source: BoostsSourceEnum = BoostsSourceEnum.Group;

  /** if source === BoostsSourceEnum.Custom **/
  custom: number | null = null
  required = true
}

import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import CapsMessageFilterHandlerModule from "./CapsMessageFilterHandlerModule";

export default class CapsMessageFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: CapsMessageFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "format_size",
    color: "rgba(var(--a-danger), 1)"
  };

  view = () => import('@/components/Modules/modules/Filters/CapsMessageFilterHandlerModuleView.vue')
}

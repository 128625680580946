import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import NewUsersHandlerModule from "./NewUsersHandlerModule";
import { GreetingProtectionTypeEnum } from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/types";
import {
  BindedChannelGreetingProtection,
  FormValidationGreetingProtection,
  InviteValidationGreetingProtection, MathCaptchaGreetingProtection,
  NoneGreetingProtection,
  StandardGreetingProtection, VariableCaptchaGreetingProtection
} from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection/ReputationCalculationMode";
import i18n from "@/i18n/i18n";
import store from "@/store/store";

import { plainToInstance } from "class-transformer";
import { cloneDeep } from "lodash";

export default class NewUsersHandlerModuleBuilder extends ModuleBuilder {
  model!: NewUsersHandlerModule;

  modelBackup!: NewUsersHandlerModule

  view: ModuleBuilder['view'] = () => import('../../../../../../../components/Modules/modules/Greeting/NewUsersHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.UsersModeration

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'waving_hand',
    iconStyle: 'outlined'
  }

  isRemovable() {
    return store.getters.isChatLicenseExists
  }

  get protectionType() {
    return this.model.params.protection.type
  }

  set protectionType(type: GreetingProtectionTypeEnum) {
    const protection = (this.modelBackup as NewUsersHandlerModule).params.protection

    if (type === protection.type) {
      this.model.params.protection = cloneDeep(protection)
      return
    }

    switch (type) {
      case GreetingProtectionTypeEnum.None:
        this.model.params.protection = plainToInstance(NoneGreetingProtection, {
          accept_join_requests: false
        })
        break;
      case GreetingProtectionTypeEnum.Standart:
        this.model.params.protection = plainToInstance(StandardGreetingProtection, {
          button_text: i18n.t('greeting_protection_button_text').toString()
        })
        break;
      case GreetingProtectionTypeEnum.InviteValidation:
        this.model.params.protection = plainToInstance(InviteValidationGreetingProtection, {
          count: 0,
          button_text: i18n.t('greeting_protection_button_text').toString()
        })
        break;
      case GreetingProtectionTypeEnum.FormValidation:
        this.model.params.protection = plainToInstance(FormValidationGreetingProtection, {
          form: '',
          users: {},
          admin_validation: false,
          button_text: i18n.t('greeting_protection_button_text').toString()
        })
        break;
      case GreetingProtectionTypeEnum.VariableCaptcha:
        this.model.params.protection = plainToInstance(VariableCaptchaGreetingProtection, {
          variants: [],
          variant: ''
        })
        break;
      case GreetingProtectionTypeEnum.MathCaptcha:
        this.model.params.protection = new MathCaptchaGreetingProtection()
        break;
      case GreetingProtectionTypeEnum.BindedChannel:
        this.model.params.protection = plainToInstance(BindedChannelGreetingProtection, {
          invite_link: '',
          button_text: i18n.t('greeting_protection_button_text').toString()
        })
        break;
    }
  }
}


import mainConfigs from "./chat/mainConfigs";
import moderation from "./chat/moderation/mutations";
import chatRuntimeMutations from "./chatRuntime/mutations";
import chatTriggersMutations from "./triggers/mutations";
import deepMergeObjects from "@/assets/utils/deepMergeObjects";
import deepObjectKeyPut      from "@/assets/utils/deepObjectKeyPut";
import { RootState } from "@/store/state";
import { BaseChatInfo, ChatConfig } from "@/includes/types/Chat/types";

import { merge, set } from "lodash";

export default {
  // Основныйе настройки
  ...mainConfigs,
  // Модерация
  ...moderation,
  // haveChats
  updateChatInfo(state: RootState, payload: {chatId: BaseChatInfo['chat_id'], config: Partial<BaseChatInfo>}) {
    const chat = state.chatState.chatsInfo?.chats.find(c => c.chat_id === payload.chatId)

    if (chat) {
      merge(chat, payload.config)
    }

  },
  SET_BRAND_BOT_CONFIG(state:RootState, n: ChatConfig['brand']) {
    state.chatState.brandConfig = n; // object/null
  },
  ...chatRuntimeMutations,
  ...chatTriggersMutations,
  setChatField(state:RootState, { objectPath, value }: any) {
    if (state.chatState.chat) {
      set(state.chatState.chat, objectPath, value);
    }
  },
  setObjectField(state:RootState, { object, objectPath, value }: any) {
    deepObjectKeyPut(object, objectPath, value);
  },
  DEEP_MERGE_OBJECTS(state:RootState, { target, src }: any) {
    deepMergeObjects(target, src);
  },
  EXEC_CALLBACK_IN_STORE(state:RootState, { type = 'unknown', callback }: { type: any, callback: () => void }) {
    callback()
  },
  clearChatStateFields(state: RootState) {
    state.chatState.chat = null
    state.chatState.brandConfig = null
    state.chatState.backupConfig = null
    state.chatState.configCopy = null
  }
};

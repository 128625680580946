import { IBaseModule } from "@/includes/logic/Modules/types/types";
import chatState, { ChatState }     from "./chats/state";
import triggersState from "./triggers/state";
import userState     	from "./user/state";
import Config       	from "@/config";
import postsPlanner 	from './postsPlanner/state';
import { FilterTypes } from "@/views/chat/filters/FilterTypes";
import { DeprecatedChatConfig } from "@/includes/types/Chat/types";

import { AdminTemplateModuleStore } from "piramis-base-components/src/plugins/AdminTemplate/store/StoreTypes";
import networksState, { NetworksState } from "./networks/state";

type KeeperRuntimeConfig = {
  default_modules_config: {
    ru: Array<IBaseModule>
    en: Array<IBaseModule>
  }

  filter_templates_ru: Record<FilterTypes, any>
  filter_templates_en: Record<FilterTypes, any>

  default_config_ru: DeprecatedChatConfig['config']
  default_config_en: DeprecatedChatConfig['config']

  placeholders: Record<string, any>

  license_options: Record<number, Array<string>>

  langs: Array<'en' | 'ru'>
}

export type RootState = {
  chatState: ChatState
  userState: any
  triggersState: any
  themeConfig: any
  postsPlanner: any
  networksState: NetworksState
  dashboardExtendedUsers: Array<string>
  AdminTemplate?: AdminTemplateModuleStore<KeeperRuntimeConfig>
}

const rootState: RootState = {
  // Chats state
  chatState    : chatState,
  // User state
  userState    : userState,
  // triggers
  triggersState: triggersState,
  themeConfig  : Config,
  //posts-planner state
  postsPlanner : postsPlanner,
  dashboardExtendedUsers: [],
  networksState
};

export default rootState

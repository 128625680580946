import Api from "@/includes/logic/Api";
import { IBaseModule, ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ChatConfig } from "@/includes/types/Chat/types";

import { instanceToPlain } from "class-transformer";

export default class ModulesApi {
  static async getDefaultModuleConfig (chat_id: number, type: ModuleTypesEnum): Promise<{data: { module: IBaseModule }}> {
    return Api.sendRequest('tg', 'getdefaultmoduleconfig', { chat_id, type })
  }

  static async setModuleConfig (chat_id: number, module: BaseModule): Promise<{data: { chat: ChatConfig }}> {
    return Api.sendRequest('tg', 'setmoduleconfig', { chat_id, module: instanceToPlain(module) })
  }

  static async saveSearchContent(chat_id: number, content: string): Promise<void> {
    return Api.sendRequest('tg', 'savesearchcontent', { chat_id, content })
  }
}

import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import ChatReplyFilterHandlerModule from "./ChatReplyFilterHandlerModule";

export default class ChatReplyFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: ChatReplyFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "chat",
    color: "rgba(var(--a-success), 1)"
  };
  view = () => import('@/components/Modules/modules/Filters/ChatReplyFilterHandlerModuleView.vue')
}

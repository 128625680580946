import Api from "@/includes/logic/Api";

export default {
	actions: {
		SET_CHAT_QUESTIONNAIRE(ctx, { chat_id, questionnaire }) {
			return Api.setChatQuestionnaire("tg", {
				chat_id,
				questionnaire,
			});
		},
	},
	getters: {
		CHAT_HAS_QUESTIONNAIRE: (state, getters, rootState) => rootState.chatState.chat.has_questionnaire,
	},
};

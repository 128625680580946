import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import NewUserTriggersHandlerModule from "./NewUserTriggersHandlerModule";

export default class NewUserTriggersHandlerModuleBuilder extends ModuleBuilder {
  model!: NewUserTriggersHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../../components/Modules/modules/Greeting/NewUserTriggersHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.UsersModeration

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'energy_savings_leaf',
    iconStyle: 'outlined'
  }
}


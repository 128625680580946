import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import WebLinksFilterHandlerModule from "./WebLinksFilterHandlerModule";

export default class WebLinksFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: WebLinksFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "insert_link",
    color: "rgba(var(--a-dark), 1)",
  };
  view = () => import('@/components/Modules/modules/Filters/WebLinksFilterHandlerModuleView.vue')
}

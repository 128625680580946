import { ModuleTypesEnum } from "./types/types";
import BaseModule from "./models/BaseModule";

import ApiNotifyHandlerModule from "./models/modules/ApiNotifyHandlerModule/ApiNotifyHandlerModule";
import ChatTopCommandHandlerModule from "./models/modules/ChatTopCommandHandlerModule/ChatTopCommandHandlerModule";
import ReferralModule from "./models/modules/ReferralModule/ReferralModule";
import AnchorsHandlerModule from "./models/modules/AnchorsHandlerModule/AnchorsHandlerModule";

import ReportCommandHandlerModule from "./models/modules/ReportCommandHandlerModule/ReportCommandHandlerModule";
import ModerateCommandHandlerModule from "./models/modules/ModerateCommandHandlerModule/ModerateCommandHandlerModule";
import CommandLimitHandlerModule from "./models/modules/CommandLimitHandlerModule/CommandLimitHandlerModule";
import RulesCommandHandlerModule from "./models/modules/RulesCommandHandlerModule/RulesCommandHandlerModule";

import SendWakeupMessageModule from "./models/modules/SendWakeupMessageModule/SendWakeupMessageModule";
import SendAdminMessageModule from "./models/modules/SendAdminMessageModule/SendAdminMessageModule";
import EntertainmentHandlerModule from "./models/modules/EntertainmentHandlerModule/EntertainmentHandlerModule";
import EntertainmentCallHandlerModule
  from "./models/modules/EntertainmentCallHandlerModule/EntertainmentCallHandlerModule";
import FirstInactiveKickHandlerModule
  from "./models/modules/FirstInactiveKickHandlerModule/FirstInactiveKickHandlerModule";
import ChatCryptoCommandHandlerModule
  from "./models/modules/ChatCryptoCommandHandlerModule/ChatCryptoCommandHandlerModule";
import InactiveKickHandlerModule from "./models/modules/InactiveKickHandlerModule/InactiveKickHandlerModule";
import DailyReportModule from "./models/modules/DailyReportModule/DailyReportModule";

import AlbumLimitHandlerModule from "./models/modules/AlbumLimitHandlerModule/AlbumLimitHandlerModule";
import CloseChatHandlerModule from "./models/modules/CloseChatHandlerModule/CloseChatHandlerModule";
import AutocorrectionHandlerModule from "./models/modules/AutocorrectionHandlerModule/AutocorrectionHandlerModule";
import NewUsersAttackHandlerModule from "./models/modules/NewUsersAttackHandlerModule/NewUsersAttackHandlerModule";
import NsfwHandlerModule from "./models/modules/NsfwHandlerModule/NsfwHandlerModule";
import EditMediaHandlerModule from "./models/modules/EditMediaHandlerModule/EditMediaHandlerModule";
import FacecontrolHandlerModule from "./models/modules/FacecontrolHandlerModule/FacecontrolHandlerModule";
import MessageBindedChannelHandlerModule
  from "./models/modules/MessageBindedChannelHandlerModule/MessageBindedChannelHandlerModule";
import NewUsersLimitHandlerModule from "./models/modules/NewUsersLimitHandlerModule/NewUsersLimitHandlerModule";
import BotGuardHandlerModule from "./models/modules/BotGuardHandlerModule/BotGuardHandlerModule";
import EnterBindedChannelHandlerModule
  from "./models/modules/EnterBindedChannelHandlerModule/EnterBindedChannelHandlerModule";
import StopWordFilterHandlerModule
  from "./models/modules/filters/StopWordFilterHandlerModule/StopWordFilterHandlerModule";
import StoryFilterHandlerModule from "./models/modules/filters/StoryFilterHandlerModule/StoryFilterHandlerModule";
import VideoNoteFilterHandlerModule
  from "./models/modules/filters/VideoNoteFilterHandlerModule/VideoNoteFilterHandlerModule";
import SpoilerMessageFilterHandlerModule
  from "./models/modules/filters/SpoilerMessageFilterHandlerModule/SpoilerMessageFilterHandlerModule";
import ContactFilterHandlerModule from "./models/modules/filters/ContactFilterHandlerModule/ContactFilterHandlerModule";
import DiceFilterHandlerModule from "./models/modules/filters/DiceFilterHandlerModule/DiceFilterHandlerModule";
import AudioFilterHandlerModule from "./models/modules/filters/AudioFilterHandlerModule/AudioFilterHandlerModule";
import HieroglyphMessageFilterHandlerModule
  from "./models/modules/filters/HieroglyphMessageFilterHandlerModule/HieroglyphMessageFilterHandlerModule";
import RtlMessageFilterHandlerModule
  from "./models/modules/filters/RtlMessageFilterHandlerModule/RtlMessageFilterHandlerModule";
import VoiceFilterHandlerModule from "./models/modules/filters/VoiceFilterHandlerModule/VoiceFilterHandlerModule";
import AnimationFilterHandlerModule
  from "./models/modules/filters/AnimationFilterHandlerModule/AnimationFilterHandlerModule";
import VideoFilterHandlerModule from "./models/modules/filters/VideoFilterHandlerModule/VideoFilterHandlerModule";
import PhotoFilterHandlerModule from "./models/modules/filters/PhotoFilterHandlerModule/PhotoFilterHandlerModule";
import DocumentFilterHandlerModule
  from "./models/modules/filters/DocumentFilterHandlerModule/DocumentFilterHandlerModule";
import RegularExpressionFilterHandlerModule
  from "./models/modules/filters/RegularExpressionFilterHandlerModule/RegularExpressionFilterHandlerModule";
import PermittedWordFilterHandlerModule
  from "./models/modules/filters/PermittedWordFilterHandlerModule/PermittedWordFilterHandlerModule";
import CensureFilterHandlerModule from "./models/modules/filters/CensureFilterHandlerModule/CensureFilterHandlerModule";
import CapsMessageFilterHandlerModule
  from "./models/modules/filters/CapsMessageFilterHandlerModule/CapsMessageFilterHandlerModule";

import MessageLengthFilterHandlerModule
  from "./models/modules/filters/MessageLengthFilterHandlerModule/MessageLengthFilterHandlerModule";
import AnyMessageFilterHandlerModule
  from "./models/modules/filters/AnyMessageFilterHandlerModule/AnyMessageFilterHandlerModule";
import ChatReplyFilterHandlerModule
  from "./models/modules/filters/ChatReplyFilterHandlerModule/ChatReplyFilterHandlerModule";
import ChannelReplyFilterHandlerModule
  from "./models/modules/filters/ChannelReplyFilterHandlerModule/ChannelReplyFilterHandlerModule";
import ChannelMessageFilterHandlerModule
  from "./models/modules/filters/ChannelMessageFilterHandlerModule/ChannelMessageFilterHandlerModule";
import BotReplyFilterHandlerModule
  from "./models/modules/filters/BotReplyFilterHandlerModule/BotReplyFilterHandlerModule";
import StickerFilterHandlerModule from "./models/modules/filters/StickerFilterHandlerModule/StickerFilterHandlerModule";
import FloodFilterHandlerModule from "./models/modules/filters/FloodFilterHandlerModule/FloodFilterHandlerModule";
import BotCommandFilterHandlerModule
  from "./models/modules/filters/BotCommandFilterHandlerModule/BotCommandFilterHandlerModule";
import StickerPackFilterHandlerModule
  from "./models/modules/filters/StickerPackFilterHandlerModule/StickerPackFilterHandlerModule";
import EmailLinksFilterHandlerModule
  from "./models/modules/filters/EmailLinksFilterHandlerModule/EmailLinksFilterHandlerModule";
import EmojiFilterHandlerModule from "./models/modules/filters/EmojiFilterHandlerModule/EmojiFilterHandlerModule";
import ReferralFilterHandlerModule
  from "./models/modules/filters/ReferralFilterHandlerModule/ReferralFilterHandlerModule";
import WebLinksFilterHandlerModule
  from "./models/modules/filters/WebLinksFilterHandlerModule/WebLinksFilterHandlerModule";
import SelfLinksFilterHandlerModule
  from "./models/modules/filters/SelfLinksFilterHandlerModule/SelfLinksFilterHandlerModule";
import XpHandlerModule from "./models/modules/XpHandlerModule/XpHandlerModule";
import RanksHandlerModule from "./models/modules/RanksHandlerModule/RanksHandlerModule";
import ReputationMessageHandlerModule
  from "./models/modules/ReputationMessageHandlerModule/ReputationMessageHandlerModule";
import WarnCommandHandlerModule from "./models/modules/WarnCommandHandlerModule/WarnCommandHandlerModule";
import LeaveUsersHandlerModule from "./models/modules/Greeting/LeaveUsersHandlerModule/LeaveUsersHandlerModule";
import SilentInviteHandlerModule from "./models/modules/Greeting/SilentInviteHandlerModule/SilentInviteHandlerModule";
import SilentAdminInviteHandlerModule
  from "./models/modules/Greeting/SilentAdminInviteHandlerModule/SilentAdminInviteHandlerModule";
import NewUserTriggersHandlerModule
  from "./models/modules/Greeting/NewUserTriggersHandlerModule/NewUserTriggersHandlerModule";
import NewUserDeprecatedTriggersHandlerModule
  from "./models/modules/Greeting/NewUserDeprecatedTriggersHandlerModule/NewUserDeprecatedTriggersHandlerModule";
import NewUsersHandlerModule from "./models/modules/Greeting/NewUsersHandlerModule/NewUsersHandlerModule";
import LocationFilterHandlerModule
  from "./models/modules/filters/LocationFilterHandlerModule/LocationFilterHandlerModule";
import FirstCommentHandlerModule
  from "./models/modules/FirstCommentHandlerModule/FirstCommentHandlerModule";
import RestrictCommentHandlerModule
  from "./models/modules/RestrictCommentHandlerModule/RestrictCommentHandlerModule";
import VotebanHandlerModule from "./models/modules/VotebanHandlerModule/VotebanHandlerModule";

import { ClassConstructor } from "class-transformer";
import NetworksModerateCommandHandlerModule from "./models/modules/NetworksModerateCommandHandlerModule/NetworksModerateCommandHandlerModule";

export const ModulesTypes: Array<{ name: ModuleTypesEnum, value: ClassConstructor<BaseModule> }> = [
  {
    name: ModuleTypesEnum.ReferralModule,
    value: ReferralModule
  },
  {
    name: ModuleTypesEnum.AnchorsHandlerModule,
    value: AnchorsHandlerModule
  },
  {
    name: ModuleTypesEnum.ApiNotifyHandlerModule,
    value: ApiNotifyHandlerModule
  },
  {
    name: ModuleTypesEnum.ReportCommandHandlerModule,
    value: ReportCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.ChatTopCommandHandlerModule,
    value: ChatTopCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.ModerateCommandHandlerModule,
    value: ModerateCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.CommandLimitHandlerModule,
    value: CommandLimitHandlerModule
  },
  {
    name: ModuleTypesEnum.RulesCommandHandlerModule,
    value: RulesCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.SendWakeupMessageModule,
    value: SendWakeupMessageModule
  },
  {
    name: ModuleTypesEnum.SendAdminMessageModule,
    value: SendAdminMessageModule
  },
  {
    name: ModuleTypesEnum.EntertainmentHandlerModule,
    value: EntertainmentHandlerModule
  },
  {
    name: ModuleTypesEnum.EntertainmentCallHandlerModule,
    value: EntertainmentCallHandlerModule
  },
  {
    name: ModuleTypesEnum.ChatCryptoCommandHandlerModule,
    value: ChatCryptoCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.InactiveKickHandlerModule,
    value: InactiveKickHandlerModule
  },
  {
    name: ModuleTypesEnum.FirstInactiveKickHandlerModule,
    value: FirstInactiveKickHandlerModule
  },
  {
    name: ModuleTypesEnum.DailyReportModule,
    value: DailyReportModule
  },
  /* extra moderation */
  {
    name: ModuleTypesEnum.AlbumLimitHandlerModule,
    value: AlbumLimitHandlerModule
  },
  {
    name: ModuleTypesEnum.CloseChatHandlerModule,
    value: CloseChatHandlerModule
  },
  {
    name: ModuleTypesEnum.AutocorrectionHandlerModule,
    value: AutocorrectionHandlerModule
  },
  {
    name: ModuleTypesEnum.NewUsersAttackHandlerModule,
    value: NewUsersAttackHandlerModule
  },
  {
    name: ModuleTypesEnum.NsfwHandlerModule,
    value: NsfwHandlerModule
  },
  {
    name: ModuleTypesEnum.EditMediaHandlerModule,
    value: EditMediaHandlerModule
  },
  {
    name: ModuleTypesEnum.FacecontrolHandlerModule,
    value: FacecontrolHandlerModule
  },
  {
    name: ModuleTypesEnum.MessageBindedChannelHandlerModule,
    value: MessageBindedChannelHandlerModule
  },
  {
    name: ModuleTypesEnum.NewUsersLimitHandlerModule,
    value: NewUsersLimitHandlerModule
  },
  {
    name: ModuleTypesEnum.BotGuardHandlerModule,
    value: BotGuardHandlerModule
  },
  {
    name: ModuleTypesEnum.EnterBindedChannelHandlerModule,
    value: EnterBindedChannelHandlerModule
  },
  // filters
  {
    name: ModuleTypesEnum.StopWordFilterHandlerModule,
    value: StopWordFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.StoryFilterHandlerModule,
    value: StoryFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.VideoNoteFilterHandlerModule,
    value: VideoNoteFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.SpoilerMessageFilterHandlerModule,
    value: SpoilerMessageFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.ContactFilterHandlerModule,
    value: ContactFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.DiceFilterHandlerModule,
    value: DiceFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.AudioFilterHandlerModule,
    value: AudioFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.HieroglyphMessageFilterHandlerModule,
    value: HieroglyphMessageFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.RtlMessageFilterHandlerModule,
    value: RtlMessageFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.VoiceFilterHandlerModule,
    value: VoiceFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.AnimationFilterHandlerModule,
    value: AnimationFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.VideoFilterHandlerModule,
    value: VideoFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.PhotoFilterHandlerModule,
    value: PhotoFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.DocumentFilterHandlerModule,
    value: DocumentFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.RegularExpressionFilterHandlerModule,
    value: RegularExpressionFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.PermittedWordFilterHandlerModule,
    value: PermittedWordFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.CensureFilterHandlerModule,
    value: CensureFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.CapsMessageFilterHandlerModule,
    value: CapsMessageFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.MessageLengthFilterHandlerModule,
    value: MessageLengthFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.ChannelMessageFilterHandlerModule,
    value: ChannelMessageFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.AnyMessageFilterHandlerModule,
    value: AnyMessageFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.ChatReplyFilterHandlerModule,
    value: ChatReplyFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.ChannelReplyFilterHandlerModule,
    value: ChannelReplyFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.BotReplyFilterHandlerModule,
    value: BotReplyFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.StickerFilterHandlerModule,
    value: StickerFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.FloodFilterHandlerModule,
    value: FloodFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.BotCommandFilterHandlerModule,
    value: BotCommandFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.StickerPackFilterHandlerModule,
    value: StickerPackFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.EmailLinksFilterHandlerModule,
    value: EmailLinksFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.EmojiFilterHandlerModule,
    value: EmojiFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.ReferralFilterHandlerModule,
    value: ReferralFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.WebLinksFilterHandlerModule,
    value: WebLinksFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.SelfLinksFilterHandlerModule,
    value: SelfLinksFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.WarnCommandHandlerModule,
    value: WarnCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.ReputationMessageHandlerModule,
    value: ReputationMessageHandlerModule
  },
  {
    name: ModuleTypesEnum.XpHandlerModule,
    value: XpHandlerModule
  },
  {
    name: ModuleTypesEnum.RanksHandlerModule,
    value: RanksHandlerModule
  },
  {
    name: ModuleTypesEnum.LeaveUsersHandlerModule,
    value: LeaveUsersHandlerModule
  },
  {
    name: ModuleTypesEnum.SilentInviteHandlerModule,
    value: SilentInviteHandlerModule
  },
  {
    name: ModuleTypesEnum.SilentAdminInviteHandlerModule,
    value: SilentAdminInviteHandlerModule
  },
  {
    name: ModuleTypesEnum.NewUserDeprecatedTriggersHandlerModule,
    value: NewUserDeprecatedTriggersHandlerModule
  },
  {
    name: ModuleTypesEnum.NewUserTriggersHandlerModule,
    value: NewUserTriggersHandlerModule
  },
  {
    name: ModuleTypesEnum.NewUsersHandlerModule,
    value: NewUsersHandlerModule
  },
  {
    name: ModuleTypesEnum.LocationFilterHandlerModule,
    value: LocationFilterHandlerModule
  },
  {
    name: ModuleTypesEnum.FirstCommentHandlerModule,
    value: FirstCommentHandlerModule
  },
  {
    name: ModuleTypesEnum.RestrictCommentHandlerModule,
    value: RestrictCommentHandlerModule
  },
  {
    name: ModuleTypesEnum.NetworksModerateCommandHandlerModule,
    value: NetworksModerateCommandHandlerModule
  },
  {
    name: ModuleTypesEnum.VotebanHandlerModule,
    value: VotebanHandlerModule
  },
]

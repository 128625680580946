import Api                     from "../../includes/logic/Api";
import VuTriggers              from "@/includes/logic/VuTriggers";
import TriggerStatDataAdapter  from "@/includes/logic/TriggerStatDataAdapter";

export default {
  actions: {
    GET_CHAT_TRIGGERS_STAT(ctx, { chat_id }) {
      return new Promise((resolve, reject) => {
	Api.getChatTriggersStat("tg", { chat_id })
	   .then(res => {
	     const tmpDataObject = {};
	     res.data.triggers_stat.forEach(statItem => {
	       if(tmpDataObject[ statItem.trigger ] === undefined) {
		 tmpDataObject[ statItem.trigger ] = [];
	       }
	       tmpDataObject[ statItem.trigger ].push(statItem);
	     });

	     VuTriggers
		  .updateTriggers()
		  .then(() => {
		    for(let key in tmpDataObject) {
		      if(tmpDataObject.hasOwnProperty(key)) {
			const triggerStatAdapter = new TriggerStatDataAdapter(key, tmpDataObject[ key ], res.data.period_start, res.data.period_end);
			tmpDataObject[ key ]     = triggerStatAdapter.getDataFormattedForApexChartsStandard(VuTriggers.getTriggerFormVuex(key));
		      }
		    }
		    resolve(Object.values(tmpDataObject));
		  });
	   })
	   .catch(reject);
      });
    },
  },
  getters: {},
};

import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import store from "@/store/store";
import { ADVANCED_TAG } from "@/includes/constants";
import EmojiFilterHandlerModule from "./EmojiFilterHandlerModule";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class EmojiFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: EmojiFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "emoji_emotions",
    color: "rgba(var(--a-primary), 1)"
  };
  view =  () => import('@/components/Modules/modules/Filters/EmojiFilterHandlerModuleView.vue')

  tariffTags(): Array<TagData> | null {
    return store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.hasAtLeastOneAdvanced) ? ADVANCED_TAG : null
  }
}

import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import StoryFilterHandlerModule from "./StoryFilterHandlerModule";

export default class StoryFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: StoryFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "camera_front",
    color: "rgba(var(--a-danger), 1)"
  };
  view =  () => import('@/components/Modules/modules/Filters/StoryFilterHandlerModuleView.vue')
}

import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import ChannelReplyFilterHandlerModule from "./ChannelReplyFilterHandlerModule";

export default class ChannelReplyFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: ChannelReplyFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "forum",
    color: "rgba(var(--a-primary), 1)"
  };
  view = () => import('@/components/Modules/modules/Filters/ChannelReplyFilterHandlerModuleView.vue')
}

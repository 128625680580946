import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import ReferralModule from "@/includes/logic/Modules/models/modules/ReferralModule/ReferralModule";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class ReferralModuleBuilder extends ModuleBuilder {
  model!: ReferralModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/ReferralModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.Extra

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'diversity_3',
  }

  tariffTags(): Array<TagData> | null {
    return this.checkLimitOption('referral_system');
  }
}


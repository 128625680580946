import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import CloseChatHandlerModule from "./CloseChatHandlerModule";
import i18n from "@/i18n/i18n";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class CloseChatHandlerModuleBuilder extends ModuleBuilder {
  model!: CloseChatHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/ModerationExtra/CloseChatHandlerModuleView.vue')

  group: ModuleGroupEnum = ModuleGroupEnum.Extra

  icon: ModuleIcon = {
    type: "Pack",
    name: 'dark_mode',
    pack: "material-icons",
    iconStyle: "outlined",
  }

  aliases = [ i18n.t('module_close_chat_handler_module_alias_1').toString() ]

  tariffTags(): Array<TagData> | null {
    return this.checkLimitOption('close_chat')
  }
}


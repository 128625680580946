import moderation, { ModerationState }       from "./chat/moderation/state";
import questionnaire, { FormsState }    from "./questionnaire/state";

import { BaseChatInfo, ChatConfigSettings } from "@/includes/types/Chat/types";

export type ChatState = {
  chatsInfo: null | { chats: Array<BaseChatInfo> }
  chat: ChatConfigSettings | null
  haveChats: boolean
  brandConfig: null | any
  backupConfig: ChatConfigSettings | null
  loadingChatsInfoPromise: Promise<{ data: { chats: Array<BaseChatInfo> } }> | null
} & ModerationState & FormsState

const chatState: ChatState = {
	chatsInfo                  : null,
	chat                       : null,
	haveChats                  : true,
	brandConfig                : null,
	backupConfig               : null,
  loadingChatsInfoPromise	   : null,
  ...moderation,
	...questionnaire,
};

export default chatState

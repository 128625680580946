import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";

import SelfLinksFilterHandlerModule from "./SelfLinksFilterHandlerModule";

export default class SelfLinksFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: SelfLinksFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "near_me",
    color: "rgba(var(--a-primary), 1)"
  };
  view = () => import('@/components/Modules/modules/Filters/SelfLinksFilterHandlerModuleView.vue')
}

import { cloneDeep }           from "lodash";

export default {
	configCopyMutation(state, payload) {
	  if (payload && payload.config !== undefined) {
	    state.chatState.configCopy = payload.config
	  } else {
	    state.chatState.configCopy = cloneDeep(state.chatState.chat.config);
	  }
	},
	mergeConfigsMutation(state, n) {
		state.chatState.chat.config = cloneDeep(n);
	},
};

import { ChatConfig } from "@/includes/types/Chat/types";

export type ModerationState = {
  configCopy: ChatConfig | null
}

const moderationState: ModerationState = {
	configCopy: null,
}

export default moderationState

import { GreetingProtectionTypeEnum } from "../../types";
import BaseGreetingProtection from "./BaseGreetingProtection";

export default class FormValidationGreetingProtection extends BaseGreetingProtection {
  type: GreetingProtectionTypeEnum = GreetingProtectionTypeEnum.FormValidation

  form!: string
  users!: Record<number, string>

  admin_validation!: boolean
  button_text!: string
}

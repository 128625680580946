export default function deepMergeObjects(target, src) {
	for (const [ key, value ] of Object.entries(src)) {
		if(typeof value === 'object' && !Array.isArray(value) && value !== null) {
			if(target[key] === undefined) {
				target[key] = {}
			}
			deepMergeObjects(target[key], value)
		}else {
			target[key] = value
		}
	}
}

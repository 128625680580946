
import axios   from "axios";
// import checkWebWorkersSupport from "@/includes/helpers/checkWebWorkersSupport";
import SWorker from "simple-web-worker";
import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

export default class Api {
	static BASE_URL = 'https://api.chatkeeper.app/cabinet'
	static API_URL = this.BASE_URL + "/v1/";

	static getStaticApiUrl() {
	  const newApiUrl = localStorage && localStorage.getItem('newApiUrl')

	  if (newApiUrl) {
	    return newApiUrl
	  }

	  return Api.BASE_URL
	}

	static getApiUrl() {
		const newApiUrl = localStorage && localStorage.getItem("newApiUrl");

		if (newApiUrl) {
		  return newApiUrl
		} else {
		  const currentHostName = window.location.hostname

		  let splitHost = currentHostName.split('.')
		  if(splitHost.length === 3) {
		    splitHost[ 0 ] = 'api'
		    return 'https://' + splitHost.join('.') + '/cabinet/v1/'
		  } else {
		    return this.API_URL
		  }
		}
	}

	static getMethodUrl(type, method) {
	  	type = type ? type + '/' : ''
		return `${ this.getApiUrl() }${ type }${ method }`
	}

	static changeApi(url) {
		localStorage && localStorage.setItem("newApiUrl", url);
	}

	static sendRequest(type, method, data = {}, headers = {}, config = {}) {
	    return new Promise((resolve, reject) => {
	      PostApi.sendRequest(type, method, data, headers, config)
		     .then(resolve)
		     .catch(reject)
	    })

		// return axios({
		// 	method         : "post",
		// 	url            : Api.getMethodUrl(type, method),
		// 	data,
		// 	withCredentials: true,
		// });
	}

	// RUNTIME
	static getRuntimeConfig(type) {
		return Api.sendRequest(type, "getruntimeconfig");
	}

	// SESSION
	static getMe(type) {
		return Api.sendRequest(type, "getme");
	}

	static logout(type) {
		return Api.sendRequest(type, "logout");
	}

	static login(type, { login, password }) {
		return Api.sendRequest(
			type,
			"login",
			{
				login,
				password,
			},
		);
	}

	// CHAT
	static getChatsInfo(type) {
		return Api.sendRequest(type, "getchatsinfo");
	}

	static getChatUsersStat(statAccessKey) {
	  return axios({
	    method: 'get',
	    url: `${ this.getStaticApiUrl() }/static/privatechatmembers/${ statAccessKey }_34.json`,
	  })
	}

	static getChatReferralsStat(statAccessKey) {
	  return axios({
	    method: 'get',
	    url: `${ this.getStaticApiUrl() }/static/privatechatreferrals/${ statAccessKey }_34.json`,
	  })
	}

	static getChatConfig(type, { chat_id }) {
		return Api.sendRequest(type, "getchatconfig", { chat_id });
	}

	static setChatConfig(type, { chat_id, chat }) {
		return Api.sendRequest(type, "setchatconfig", { chat_id, ...chat });
	}

  	static setChatConfigPartial(type, { chat_id, config }) {
    		return Api.sendRequest(type, "setpartialchatconfig", { chat_id, ...config });
  	}

	static exportChatConfig(type, { chat_id, triggers }) {
	  	let query = `${ Api.getMethodUrl(type, "exportchatconfig") }?chat_id=${ chat_id }`

		if (triggers) {
		  query += `&triggers=${ triggers.join(',') }`
		}

		window.open(query, "_blank");
	}

	static exportChatTriggers(type, { triggers, chat_id }) {
		window.open(`${ Api.getMethodUrl(type, "exportchattrigger") }?chat_id=${ chat_id }&triggers=${ triggers.join(',') }`, "_blank");
	}

	static importChatConfigUrl(type) {
		return Api.getMethodUrl(type, "importchatconfig");
	}

	static setChatBrand(type, { chat_id, token }) {
		return axios({
			method         : "post",
			url            : Api.getMethodUrl("tg", "setchatbrand"),
			data           : {
				chat_id,
				token,
			},
			withCredentials: true,
		});
	}

	static runChatAction(type, { chat_id, action }) {
		return Api.sendRequest(type, "runchataction", {
			chat_id,
			action,
		});
	}

	static getJournal(type, { chat_id, offset, limit }) {
		return Api.sendRequest(type, "getjournal", {
			chat_id,
			offset,
			limit,
		});
	}

	// Brand
	static getBrandConfig(type, { chat_id }) {
		return Api.sendRequest(type, "getbrandconfig", { chat_id });
	}

	static resetChatBrand(type, { chat_id }) {
		return Api.sendRequest(type, "resetchatbrand", { chat_id });
	}

	static setBrandConfig(type, { config, chat_id }) {
		return Api.sendRequest(type, "setbrandconfig", {
		  	chat_id,
			config,
		});
	}

	// // STATISTICS
	// static getStat(type, { chat_id, metrics,from, to }) {
	// 	return Api.sendRequest(type, "getStat", {
	// 		chat_id,
	// 		from,
	// 		to,
	// 	  	metrics
	// 	});
	// }

	static getDashboardFullStat(type, { chat_id }) {
		return Api.sendRequest(type, "getdashboardfullstat", { chat_id });
	}

	static getDashboardStat(type, { chat_id }) {
		return Api.sendRequest(type, "getdashboardstat", { chat_id });
	}

	static getChatTriggersStat(type, { chat_id }) {
		return Api.sendRequest(type, "getchattriggersstat", { chat_id });
	}

	static getChatsInfoStat(type) {
		return Api.sendRequest(type, "getchatsinfostat");
	}

	// static getUsersStat(type, { chat_id, user_ids }) {
	// 	return Api.sendRequest(type, "getusersstat", {
	// 		chat_id,
	// 		user_ids,
	// 	});
	// }

	// TRIGGERS
	static deleteTrigger(type, { id }) {
		return Api.sendRequest(type, "deletetrigger", { id });
	}

	static getTrigger(type, { id }) {
		return Api.sendRequest(type, "gettrigger", { id });
	}

	static getTriggers(type) {
		return Api.sendRequest(type, "gettriggers");
	}

	static setTrigger(type, { trigger }) {
		return Api.sendRequest(type, "settrigger", trigger);
	}

	// get triggers by chat id
	static getChatTriggers(type, { chat_id }) {
	  return Api.sendRequest(type, "getchattriggers", { chat_id });
	}

	// SEND MESSAGE
	static sendSelfMessage(type, { chat_id, message }) {
		return Api.sendRequest(type, "sendselfmessage", { chat_id,
			message });
	}

	static sendChatMessage(type, { chat_id, message }) {
		return Api.sendRequest(type, "sendchatmessage", {
			chat_id,
			message,
		});
	}

	// PAYMENTS
	static runCoupon(type, { chat_id, target, promocode }) {
		return Api.sendRequest(type, "getpaymethods", {
			chat_id,
			target,
			promocode,
		});
	}

	static getPayMethods(type, { chat_id, chat_key, target, invoice_key }) {
		return Api.sendRequest(type, "getpaymethods", {
			chat_key,
			chat_id,
			invoice_key,
			target
		});
	}

	static getPayInfo(type, { invoice }) {
		return Api.sendRequest(type, "getpayinfo", { invoice });
	}

	static createPayment(type, { chat_id, invoice_key, chat_key, target, tariff, shop, monthCount, params, promocode }) {
		const payment = {
			target,
			tariff,
			shop,
			monthCount,
		};

		if(chat_id) {
			payment.chat_id = chat_id;
		}
		if(invoice_key) {
			payment.invoice_key = invoice_key;
		}
		if(chat_key) {
			payment.chat_key = chat_key;
		}
		if(params) {
			payment.params = params;
		}
		if(promocode) {
			payment.promocode = promocode;
		}

		return Api.sendRequest(type, "createpayment", payment);
	}

	// REGEXP
	static testRegexp(type, { pattern, string, toLowercase }) {
		return Api.sendRequest(type, "testregexp", {
			pattern,
			string,
			toLowercase,
		});
	}

	//FORMS
	static testForm(type, { fields, values }) {
		return Api.sendRequest(type, "testform", {
			fields,
			values,
		});
	}

	static runForm(type, { action, data, values }) {
		const form = {
			action,
			data,
		};

		if(values) {
			form.values = values;
		}

		return Api.sendRequest(type, "runform", form);
	}

	static deleteForm(type, { guid, chat_id }) {
		return Api.sendRequest(type, "deleteform", {
			guid,
			chat_id,
		});
	}

	static getForms(type, { chat_id }) {
		return Api.sendRequest(type, "getforms", { chat_id });
	}

	static setForm(type, { form }) {
		return Api.sendRequest(type, "setform", form);
	}

	// CHANNEL BINDING
	static bindChatChannel(type, { chat_id, channel }) {
		return Api.sendRequest(type, "bindchatchannel", {
			chat_id,
			channel,
		});
	}

	static bindChannelByCode(reqType, { chat_id, type }) {
	  return Api.sendRequest(reqType, 'bindchannelbycode', {
	    chat_id,
	    type
	  })
	}

	static unbindChatChannel(type, { chat_id }) {
		return Api.sendRequest(type, "unbindchatchannel", { chat_id });
	}

	static stateChannelByCode(type, { code }) {
	  return Api.sendRequest(type, "statechannelbycode", { code });
	}

	// QUESTIONNAIRE
	static setChatQuestionnaire(type, { chat_id, questionnaire }) {
		return Api.sendRequest(type, "setchatquestionnaire", {
			chat_id,
			questionnaire,
		});
	}

	static setQuestionnaire(type, { questionnaire }) {
		return Api.sendRequest(type, "setuserquestionnaire", { questionnaire });
	}

	// CHAT BACKUPS
	static getChatBackups(type, { chat_id }) {
		return Api.sendRequest(type, "getchatbackups", { chat_id });
	}

	static restoreBackup(type, { chat_id, code }) {
		return Api.sendRequest(type, "restorebackup", { chat_id, code });
	}

	// TRANSFER LICENSE
  	static transferLicense(type, { chat_id, target_id, license_type }) {
	  if (license_type === 0 || license_type) {
	    return Api.sendRequest(type, "transferlicense", { chat_id, target_id, license_type });
	  }
	  return Api.sendRequest(type, "transferlicense", { chat_id, target_id });
	}

	// EXERNAL INTEGRATIONS
	static generateAccessToken(type, { chat_id }) {
		return Api.sendRequest(type, "generateaccesstoken", { chat_id });
	}

	// BILLING INFO
	static getBonusInfo(type) {
		return Api.sendRequest(type, 'getbonusinfo')
	}

	static bonusInfoType = {
		'full': 'full',
		'invoice': 'invoice',
		'ref': 'ref',
	}

	static getBonusHistory(type, { bonusInfoType }) {
		return Api.sendRequest(type, 'getbonushistory', { 'type': bonusInfoType })
	}

	// CHAT ADDING
	// 	createinstallrequest
	// {
	// "lang": "ru", // getRuntimeConfig - список доступных чатов
	// 	"params": {
	//		тарифф - получить из getRuntimeConfig
	// 	}
	// }
	//
	// {
	// 	"key": "9.75a9c47b5f3147c98a5121178204dd6c",
	// 	"result": "ok"
	// }
	//
	//
	//
	// getinstallrequestchats
	// {
	// 	"key" : "9.75a9c47b5f3147c98a5121178204dd6c"
	// }
	// {
	// 	"add_chat_url": "https://t.me/TdiDebug2Bot?start=DjU8IoIlR4TfTCkswfXqWw",
	// 	"chats": [
	// 	{
	// 		"chat_id": -1001420502462,
	// 		"member_count": 6,
	// 		"group_title": "Тестовая группа 221",
	// 		"group_code": "faafffaaafffaaa",
	// 		"group_photo": "https://cabinet.chatkeeper.app/avatar/chat/chat-1001420502462.jpeg",
	// 		"group_description": ""
	// 	}
	// ],
	// 	"params": {
	//		тарифф - получить из getRuntimeConfig
	// 	}
	// "result": "ok"
	// }
	static createInstallRequest(type, { lang, params }) {
		return Api.sendRequest(type, 'createinstallrequest', { lang, params })
	}

	static getInstallRequestChats(type, { key }) {
		return Api.sendRequest(type, 'getinstallrequestchats', { key })
	}

	// WORKFLOW
	static updateFlow(type, { key, board, title, flow }) {
	  return Api.sendRequest(type, 'updateflow', { key, board, title, flow })
	}

	static createFlow(type, { board, title, flow }) {
	  return Api.sendRequest(type, 'createflow',{ board, title, flow })
	}

	static getFlow(type, { key }) {
	  return Api.sendRequest(type, 'getflow', { key })
	}

	static deleteFlow(type, { key }) {
	  return Api.sendRequest(type, 'deleteflow', { key })
	}

	static getFlows(type, { board }) {
	  return Api.sendRequest(type, 'getflows', { board })
	}

	// RESETS
	static resetChatRep(type, { chat_id }) {
	  return Api.sendRequest(type, "resetchatrep", { chat_id });
	}

	static resetChatAp(type, { chat_id }) {
	  return Api.sendRequest(type, "resetchatap", { chat_id });
	}

	static resetChatXp(type, { chat_id }) {
	  return Api.sendRequest(type, "resetchatxp", { chat_id });
	}

	static resetChatRank(type, { chat_id }) {
	  return Api.sendRequest(type, "resetchatrank", { chat_id });
	}

	// UR REGISTRATION
	static validateEmail(type, { email }) {
	  return Api.sendRequest(type, 'validateemail', { email })
	}

	static createdOrganization(type, { data } ) {
	  return Api.sendRequest(type, 'createorganization', data, { "Content-Type": "multipart/form-data" })
	}

	// INVITE LINKS
  	static getInviteLinks(type, chat_id){
	  return Api.sendRequest(type, 'getinvitelinks', { chat_id })
	}

	static createinvitelink(type, body){
	  return Api.sendRequest(type, 'createinvitelink', body)
	}

	static deleteinvitelink(type, body){
	  return Api.sendRequest(type, 'deleteinvitelink', body)
	}

	static setinvitelink(type, body){
	  return Api.sendRequest(type, 'setinvitelink', body)
	}

	static getinvitelinksstat(type, body){
	  return Api.sendRequest(type, 'getinvitelinksstat', body)
	}

	//post-planner
	// static getPostBoards(type) {
	//   return Api.sendRequest(type, 'getpostboards')
	// }

	static createPost(type, body) {
	  return Api.sendRequest(type, 'createpost', body)
	}

	static testPost(type, body) {
	  return Api.sendRequest(type, 'testpost', body)
	}

	static getPostPreview(type, body, config) {
		return Api.sendRequest(type, 'getPostPreview', body, {}, config)
	}

	static getSavedPosts(type, body) {
	  return Api.sendRequest(type, 'getsavedposts', body)
	}

  	static deleteSavedPost(type, body) {
  	  return Api.sendRequest(type, 'deletesavedpost', body)
  	}

	static getPosts(type, body) {
	  return Api.sendRequest(type, 'getposts', body)
	}

	static getPostInfo(type, body) {
	  return Api.sendRequest(type, 'getpostinfo', body)
	}

	static getOriginalPost(type, body) {
	  return Api.sendRequest(type, 'getoriginalpost', body)
	}

	static deletePost(type, body) {
	  return Api.sendRequest(type, 'deletepost', body)
	}

  	static savePost(type, body) {
  	  return Api.sendRequest(type, 'savepost', body)
  	}

  	static movePost(type, body) {
  	  return Api.sendRequest(type, 'movepost', body)
  	}

	//board config
	static getBoardConfig(type, body) {
	  return Api.sendRequest(type, 'getpostboardconfig', body)
	}

	static setBoardConfig(type, body) {
	  return Api.sendRequest(type, 'setpostboardconfig', body)
	}

	static clearUsers(type, body) {
	  return Api.sendRequest(type, 'clearusers', body)
	}
}
